import { render, staticRenderFns } from "./curriculumSystem.vue?vue&type=template&id=78bacec0&scoped=true"
import script from "./curriculumSystem.vue?vue&type=script&lang=js"
export * from "./curriculumSystem.vue?vue&type=script&lang=js"
import style0 from "./curriculumSystem.vue?vue&type=style&index=0&id=78bacec0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78bacec0",
  null
  
)

export default component.exports