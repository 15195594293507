<template>
  <div class="course">
    <TabPageLayout activeCode="yizhongkecheng">
      <div class="container curriculumSystem growthPlatform">
        <div class="div_mod">
          <div v-for="(block, index) in blocks" :key="block.id">
            <div class="div_tit">
              <h2>{{ block.title }}</h2>
              <div v-if="blockType(block.code) !== 'blockType2'" v-html="block.bz"></div>
            </div>

            <div v-if="blockArticles[index].length">
              <div class="div_mod4" v-if="block.code === 'qa'">
                <ul class="ul_qa">
                  <li v-for="article in blockArticles[index]" :key="article.id">
                    <h3>{{ article.title }}</h3>
                    <div class="div_text" v-html="article.content"></div>
                  </li>
                </ul>
              </div>

              <div v-else-if="blockType(block.code) === 'blockType3'">
                <div class="lunbo">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide"
                        v-for="item in blockArticles[index]"
                        :key="item.id"
                      >
                        <img :src="item.files" alt="" />
                        <p>{{ item.title }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="direction">
                    <span class="s_prev"><i></i></span>
                    <span class="s_next"><i></i></span>
                  </div>
                </div>
              </div>

              <div
                class="div_mod4"
                v-else-if="blockType(block.code) === 'blockType1'"
              >
                <div class="lunbo_txtImg lunbo_txtImg_SP">
                  <dl>
                    <dt>
                      <div class="swiper-container">
                        <div class="swiper-wrapper">
                          <div
                            class="swiper-slide"
                            v-for="article in blockArticles[index]"
                            :key="article.id"
                          >
                            <img :src="article.files" alt="" />
                            <p>{{ article.title }}</p>
                          </div>
                        </div>
                      </div>
                    </dt>
                    <dd>
                      <div class="swiper-container">
                        <div class="swiper-wrapper">
                          <div
                            class="swiper-slide"
                            v-for="article in blockArticles[index]"
                            :key="article.id"
                          >
                            <div
                              class="div_text"
                              v-html="article.content"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </dd>
                  </dl>

                  <div class="div_swiper_icons">
                    <div class="swiper-container swiper_icons">
                      <div class="swiper-wrapper">
                        <div
                          class="swiper-slide"
                          v-for="article in blockArticles[index]"
                          :key="article.id"
                        >
                          <img :src="article.files" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="direction">
                      <span class="s_prev"><i></i></span>
                      <span class="s_next"><i></i></span>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="div_mod3">
                <dl class="dlImgSlide">
                  <dt v-if="blockArticles[index].length">
                    <div class="swiper-container">
                      <div class="swiper-wrapper">
                        <div
                          class="swiper-slide"
                          v-for="article in blockArticles[index]"
                          :key="article.id"
                        >
                          <img :src="article.files" alt="" />
                        </div>
                      </div>
                    </div>

                    <div class="arrow">
                      <span class="s_prev"><i></i></span>
                      <span class="s_next"><i></i></span>
                    </div>
                  </dt>
                  <dd>
                    <div v-html="block.bz" class="div_text"></div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TabPageLayout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabPageLayout from './components/TabPageLayout.vue'
import { useGetCategories } from './utils/useGetCategories'
import portalApi from '../../../lvya-common/src/api/eos/portal'

export default {
  components: {
    TabPageLayout,
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    typeId() {
      return this.$route.query.typeId
    },
  },
  data() {
    return {
      blocks: [],
      blockArticles: [],
    }
  },
  methods: {
    blockType(code) {
      // 图片右侧轮播，有缩略图
      const blockType1 = ['CompetitionResearch', 'ClubActivities']
      // 图片右侧轮播，无缩略图
      // const blockType2 = []
      // 只有图片轮播
      const blockType3 = ['ArtAndSports']
      // 只有简介
      const blockMain = ['AP_Programme', 'CareerPlanning', 'GrowthPlatform']
      if (blockType1.includes(code)) {
        return 'blockType1'
      } else if (blockType3.includes(code)) {
        return 'blockType3'
      } else if (blockMain.includes(code)) {
        return 'blockMain'
      } else {
        return 'blockType2'
      }
    },
    async getBlocks() {
      const categories = await useGetCategories(this.typeId)
      console.log({ categories })
      this.blocks = categories.list
    },
    async getArticles() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      const articles = []
      for (const block of this.blocks) {
        console.log(block, 'block')
        let reqData = {
          noSchoolId: true,
          schoolId: '7',
          pageNum: 1,
          pageSize: 999,
          typeId: block.id,
          isContent: 'Y',
          applyState: 'Y',
        }
        let resData = await portalApi.getArtsByPage(reqData)
        console.log(resData, 'articles')
        articles.push(resData?.list)
        console.log(articles, 'articles')
      }
      this.blockArticles = articles
    },
  },
  async mounted() {
    await this.getBlocks()
    await this.getArticles()

    new Swiper('.dlImgSlide .swiper-container', {
      loop: true,
      slidesPerView: 1, // 一行显示3个
      spaceBetween: 20,
      navigation: {
        nextEl: '.dlImgSlide .s_next',
        prevEl: '.dlImgSlide .s_prev',
      },
    })
    new Swiper('.lunbo .swiper-container', {
      loop: true,
      slidesPerView: 1, // 一行显示3个
      spaceBetween: 30,
      navigation: {
        nextEl: '.lunbo .s_next',
        prevEl: '.lunbo .s_prev',
      },

      breakpoints: {
        1024: {
          // 当屏幕宽度大于等于320
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
    })

    let swicons = new Swiper('.div_mod4 .lunbo_txtImg_SP .swiper_icons', {
      spaceBetween: 5,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    })

    new Swiper('.div_mod4 .lunbo_txtImg_SP dl dt .swiper-container', {
      thumbs: {
        swiper: swicons,
      },
      navigation: {
        nextEl: ' .div_mod4 .lunbo_txtImg_SP .s_next',
        prevEl: ' .div_mod4 .lunbo_txtImg_SP .s_prev',
      },
      on: {
        slideChange: function() {
          var spanTitle = $('.div_mod4 .lunbo_txtImg dl dd .swiper-slide').eq(
            this.realIndex
          )
          spanTitle.addClass('show').siblings().removeClass('show')

          var sw = $('.div_mod4 .lunbo_txtImg dl dd .swiper-slide').width()

          $('.div_mod4 .lunbo_txtImg dl dd .swiper-wrapper').animate({
            left: -sw * this.realIndex,
          })
        },
      },
    })
  },
}
</script>

<style lang="less" scoped>
@import "./style/course.css";
@import "./style/common.css";
@import "./style/global.css";
.container .div_tit .div2 span {
  color: #fff;
  text-align: center;
  display: block;
  width: 100%;
}
.div_tit {
  margin-top: 0.5rem;
}
.lunbo_txtImg.lunbo_txtImg_SP{
  position: relative;
  .div_text{
    max-height: 60%;
    overflow: hidden;
  }
}
</style>
